/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    em: "em",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, TwoColCta, LandingPageCta, SideBySide, MapInfo, ThreeColCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  if (!TwoColCta) _missingMdxReference("TwoColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-you-need-to-know-about-hearing-aid-reviews",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-you-need-to-know-about-hearing-aid-reviews",
    "aria-label": "what you need to know about hearing aid reviews permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What you need to know about hearing aid reviews"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are an important investment that requires careful consideration. To make your search for a suitable solution easier, our experts have written reviews about some of our top-selling devices. One thing to keep in mind about the reviews you read on the internet is that they can be subjective. This is because ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "everyone has different degrees of hearing loss"), ", needs and preferences, so one device may seem like a good fit but you won’t know for sure ", React.createElement(_components.a, {
    href: "/hearing-loss/hearing-test/",
    className: "c-md-a"
  }, "until you have your hearing evaluated"), ". However, the reviews you will read here should give you a general overview of what you can expect from some of our best products. If you have questions about any of the hearing aids presented on this page, feel free to contact us for a free consultation."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "our-best-recommendations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#our-best-recommendations",
    "aria-label": "our best recommendations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Our Best Recommendations"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Two of the best hearing aids on the market today include the Horizon Go IX and the Horizon Mini IX, both which are part of the ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/",
    className: "c-md-a"
  }, "exclusive Horizon IX line of hearing aids"), ". No matter if you have mild or severe hearing loss, one of these state-of-the-art devices is sure to work for you."), "\n", React.createElement(TwoColCta, {
    imgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids.png",
    copy1: "Horizon Go IX",
    url1: "/hearing-aids/horizon/go-ix/",
    imgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/mini-ix-hearing-aids.png",
    copy2: "Horizon Mini IX",
    url2: "/hearing-aids/horizon/mini-ix/"
  }), "\n", React.createElement(LandingPageCta, {
    copy: "Start 45-day Trial Today",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "expert-reviews-of-our-best-sellers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#expert-reviews-of-our-best-sellers",
    "aria-label": "expert reviews of our best sellers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Expert reviews of our best sellers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our team of ", React.createElement(_components.a, {
    href: "/audiologists/",
    className: "c-md-a"
  }, "hearing aid experts and audiologists"), " have put together a brief review of some of the most highly-rated devices we have to offer. If you would like to speak to someone about any of these particular models, get in touch for a free phone consultation."), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/oticon-2018-de-opn-iic.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "oticon-opn-review",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#oticon-opn-review",
    "aria-label": "oticon opn review permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Oticon OPN Review"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Oticon OPN is the first hearing aid that is scientifically proven to reduce the amount of effort expended by the brain during the use of a hearing aid. In addition to its advanced performance, the OPN is also the first hearing aid to feature a fully integrated internet connectivity. Many user report that their favorite feature is its ability to connect to smartphones, kitchen appliances, doorbells, baby monitors, televisions, radios, and even internet-connected thermostats. According to Dr. Marco Vietor, Founder and Managing Director of hear.com, the OPN is one of the best hearing aid innovations")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/oticon/#oticon-opn-s-premium-btes-and-rites",
    className: "c-md-a"
  }, "Read more about the Oticon OPN here.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/phonak-aude%CC%81o-s.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "phonak-audéo-q",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonak-aud%C3%A9o-q",
    "aria-label": "phonak audéo q permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak Audéo Q"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Audéo Q is considered to be Phonak’s signature product. This discreet device allows users to hear and understand in any listening environment. Its sophisticated technology offers many advanced features that will make your daily life more enjoyable. Some of the most attractive features are superior sound quality and automatic noise-reducing filters. Available for all degrees of hearing loss, the Audéo Q is an excellent hearing loss solution.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/phonak/audeo-q/",
    className: "c-md-a"
  }, "Read more about the Phonak Audéo Q here.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-pure312nx.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-pure-x",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-pure-x",
    "aria-label": "signia pure x permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia Pure X"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Pure X is the must-have hearing aid for anyone who loves technology. This is because the device can connect wirelessly to other Bluetooth-enabled devices, allowing individuals to stream audio directly to their hearing aid. Users can adjust the settings discreetly with the Signia’s TouchControl mobile app. In terms of performance, the Pure X uses its SpeechMaster technology to amplify only those sounds you want to hear while automatically reducing background noises. Our hearing aid experts find themselves recommending this device to their customer more than any other.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/#signia-pure-x",
    className: "c-md-a"
  }, "Read more about the Signia Pure here.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-pure-charge-go-nx-with-charger.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia-pure-chargego-x",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia-pure-chargego-x",
    "aria-label": "signia pure chargego x permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia Pure Charge&Go X"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Boasting all the same features as the Pure X, the Charge&Go X model is one of the best rechargeable hearing aids on the market. Not only does it have ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth connectivity"), " and smartphone connectivity, but it also comes with Own Voice Processing (OVP) which allows your own voice to sound very natural. The best thing about the Charge&Go X is its rechargeability. Simply place the devices in their charging station overnight and they will be ready for use the next day. Customers love the fact that they don’t have to worry about changing dead batteries anymore.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/resound-linx2.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "resound-linx-3d",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resound-linx-3d",
    "aria-label": "resound linx 3d permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ReSound LiNX 3D"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The LiNX 3D is one of the first “iPhone hearing aids” that is available for both new and experienced wearers. Its Binaural Directionality III feature allows the user to focus on the sounds he or she wants to hear, without feeling cut off from their surroundings. With the ReSound Smart 3D app, users can discreetly control the settings of the hearing aids directly from their smartphone – no need to fiddle with tiny buttons anymore!")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/resound/linx",
    className: "c-md-a"
  }, "Read more about the ReSound LiNX here.")), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "customer-reviews",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customer-reviews",
    "aria-label": "customer reviews permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customer Reviews"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A review site wouldn’t be a review site without our customers’ voices. Here are a few product reviews from some of our satisfied hearing aid wearers. If you would like to read more customer reviews, make sure to visit our testimonials page."), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-reviews-customer-agnes.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "My old hearing aids were five years old when I first saw an ad from hear.com about the “iPhone hearing aid”. Prior to calling hear.com, I had no idea that an “iPhone hearing aid” even existed. I told the consultant about my lifestyle and needs, and he recommended hearing aids that are compatible with smartphones. During my hearing aid fitting, I tested them with my iPhone. I downloaded the app and played around with the settings until I was satisfied with the sound. I was impressed with how much hearing aids have evolved."))), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "—Agnes M., 62, Springfield, MO, wears the ReSound LiNX 3D hearing aid"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-reviews-customer-aaron.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Before purchasing my hearing aids, my wife would constantly tell me to turn the TV volume down. I thought she was just nagging me…that is, until I spoke with a hearing aid expert from hear.com about my situation. I told Daniel, my consultant, about some of the difficulties I was facing with my hearing, and he informed me that I might be a candidate for hearing aids. He explained to me that the Widex Unique hearing aids would be a good fit for me. He was right! I had to adjust to the new sounds at first (which Daniel warned me about), but once I got used to it, the quality was amazing! Now, I can watch TV without driving my wife crazy."))), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "—Aaron H., 51, Fort Walton Beach, FL, wears the Widex Unique hearing aid"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "finding-the-right-hearing-aid-for-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#finding-the-right-hearing-aid-for-you",
    "aria-label": "finding the right hearing aid for you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Finding the right hearing aid for you"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The right hearing aid requires the right style, features, and ", React.createElement(_components.a, {
    href: "/hearing-aids/accessories/",
    className: "c-md-a"
  }, "accessories"), ". Most importantly, the right hearing aids must treat your specific hearing loss. Additionally, you need to think about your lifestyle."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Think about what activities you do the most:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Are you an active person involved in outdoor activities?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Do you spend most of the time at home?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Do you often have people over your home?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Do you need specific hearing aids for meetings?"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Do you attend many social events?"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The answers to these questions will help you select a hearing aid model that works with your way of life."), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/resources/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
